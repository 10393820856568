import React, { useEffect, useState } from "react";
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from "axios";
import 'swiper/css/bundle';

export default function App({gallery}) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getFlickrGallery = async () => {
      const response = await axios(
        `https://api.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=bf898196079b6aac87ed4d1845d68297&user_id=27083134@N08&photoset_id=${gallery}&format=json&nojsoncallback=1`,
        {
          method: "GET"
        }
      );

      setUser(response.data.photoset);
      setIsLoading(false);
    };

    getFlickrGallery();
  }, [gallery]);

  return (
    <section>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Swiper
          modules={[Navigation, Scrollbar, A11y]}
          centeredSlides={true}
          navigation
          slidesPerView={1.5}
          className="mySwiper"
        >
          {user.photo.map((img) => (
            <SwiperSlide key={img.id}>
              <div>
                <img src={`https://farm${img.farm}.staticflickr.com/${img.server}/${img.id}_${img.secret}.jpg`} alt={img.title} />
              </div>
            </SwiperSlide>
          ))}
        </ Swiper>
      )}
    </section>
  );
}
