import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function FullWidthImage(props) {
  const { height = 400, img, title, subheading, imgPosition = 'center center' } = props

  return (
    <React.Fragment>
      <div
        className="jumbotron"
        style={{
          display: 'grid',
          alignItems: 'center',
        }}
      >
        {img?.url ? (
          <img
            src={img}
            objectFit={'cover'}
            objectPosition={imgPosition}
            style={{
              gridArea: '1/1',
              // You can set a maximum height for the image, if you wish.
              height: height,
              width: '100%',
            }}
            // This is a presentational image, so the alt should be an empty string
            alt=""
          />
        ) : (
          <GatsbyImage
            image={img}
            objectFit={'cover'}
            objectPosition={imgPosition}
            style={{
              gridArea: '1/1',
              // You can set a maximum height for the image, if you wish.
              maxHeight: height,
            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={['auto', 'webp', 'avif']}
          />
        )}
        {(title || subheading) && (
          <div
            style={{
              gridArea: '1/1',
              position: 'relative',
              placeItems: 'center',
              display: 'grid',
              zIndex: '1',
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <h1
                className=""
                style={{
                  color: 'white',
                  lineHeight: '1',
                  padding: '0',
                }}
              >
                {title}
              </h1>
            )}
            {subheading && (
              <h3
                className=""
                style={{
                  color: 'white',
                  lineHeight: '1',
                  padding: '0.25rem',
                  marginTop: '0.5rem',
                  textAlign: 'center',
                }}
              >
                {subheading}
              </h3>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
}
