import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Gallery from '../components/Gallery'
import FullWidthImage from '../components/FullWidthImage'

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  gallery,
  helmet,
  image
}) => {
  const PostContent = contentComponent || Content
  const heroImage = getImage(image) || image


  return (
    <>
      <FullWidthImage img={heroImage} title={title} subheading={description} />
      <section className={`post`}>
        {helmet || ''}
        <PostContent content={content} />
        <Gallery gallery={gallery}/>
        <div className='btn-block'>
          <a className='btn large' href={`https://flickr.com/photos/meincken/albums/${gallery}`} rel="noreferrer" target='_blank'>View on Flickr</a>
        </div>

        {tags && tags.length ? (
          <div className="tags">
            <h4>Tags</h4>
            <ul className="taglist">
              {tags.map((tag) => (
                <li key={tag + `tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`}>{tag}, </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </section>
    </>

  )
}

BlogPostTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  gallery: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        image={post.frontmatter.featuredimage}
        gallery={post.frontmatter.gallery}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        featuredimage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        gallery
      }
    }
  }
`
